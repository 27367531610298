import { DirectionEnum } from "src/core/enums/design-system.enum";
export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
}
export function InputFiled({
  hint,
  isValid,
  ...props
}: InputFieldProps): JSX.Element {
  const getValidationPaddingClass = () => {
    switch (isValid) {
      default:
        return "px-2";
    }
  };
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500  outline-none";
      default:
        return "";
    }
  };
  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500";
      default:
        return "text-muted";
    }
  };

  return (
    <div
      dir={props.dir}
      className="w-full mb-4 mobile:flex mobile:justify-center mobile:w-auto "
    >
      {props["aria-label"] && (
        <label className="block px-2 mobile:px-7 mb-2 text-base text-white font-font-Messri">
          {props["aria-label"]}
        </label>
      )}
      <div className="relative mobile:px-6">
        {hint && (
          <p
            className={`w-[369px] pb-2 mobile:px-8 pt-1 font-Messri text-2xl px-2 mobile:w-auto mobile:text-center ${getHintClass()}`}
          >
            {hint}
          </p>
        )}
        <input
          {...props}
          className={` w-[369px] h-[50px] border-[1.5px] px-4 border-[#022A3A] mobile:w-auto
            ${getValidationClass()}
             ${props.className ?? ""}${getValidationPaddingClass()}`}
        />
        <div
          className={`absolute top-5 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-3" : "right-3"
          }`}
        >
          {props.type !== "date"}
        </div>
      </div>
    </div>
  );
}
export default InputFiled;
