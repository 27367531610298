import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

interface IProps {
  id: string;
}

const Goals = (props: IProps) => {
  const { t } = useTranslation();

  const goals = [
    {
      description: "goal1",
      image: "/assets/images/goal1.svg",
    },
    {
      description: "goal2",
      image: "/assets/images/goal2.svg",
    },
    {
      description: "goal3",
      image: "/assets/images/goal3.svg",
    },
    {
      description: "goal4",
      image: "/assets/images/goal4.svg",
    },
  ];

  return (
    <div
      id={props.id}
      className="flex justify-center flex-col laptop:mt-60 mt-[600px] mobile:mt-[780px] "
    >
      <div className="flex flex-row laptop:flex-col laptop:justify-center justify-around w-full tablet:flex-col tablet:pt-[300px] tablet:justify-center tablet:items-center mobile:flex-col mobile:justify-center">
        <div className="flex flex-col h-6 py-40 mobile:pt-96 laptop:justify-center laptop:items-center laptop:w-full w-[430px] px-4 mobile:w-auto ">
          <h1
            className={`tablet:flex w-[450px] text-center font-bold mobile:w-full px-2 pt-2 mobile:px-5 tablet:justify-center  mobile:h-auto mobile:text-center font-Messri  ${
              i18n.language === "ar" ? "text-4xl" : " text-bold text-4xl  "
            } `}
          >
            {t("Goal")}
          </h1>
          <h1
            className={`tablet:flex w-[450px] text-3xl font-bold mobile:w-full px-2 mobile:px-5 tablet:justify-center mobile:flex mobile:h-auto mobile:text-center font-Messri  ${
              i18n.language === "ar"
                ? "text-justify"
                : " text-bold text-3xl text-end "
            } `}
          >
            {t("goalDesc")}
          </h1>
        </div>
        <div className="grid grid-cols-2 laptop:mx-16  gap-8 mobile:px-10 mobile:grid-cols-1 tablet:flex-col tablet:flex tablet:pt-[270px] mobile:pt-[300px] ">
          {goals.map((e) => (
            <>
              <div className="w-[350px] h-[380px] mobile:h-auto mobile:w-auto border-[#9A9162] border-2 p-4  mobile:p-4">
                <img src={e.image} alt="" />
                <h1
                  className={`tablet:flex mobile:px-1 text-3xl p-1 tablet:justify-center mobile:flex mobile:justify-center mobile:pt-4 pt-10 font-Messri ${
                    i18n.language === "ar"
                      ? "text-justify"
                      : " text-bold text-3xl text-center "
                  }   `}
                >
                  {t(`${e.description}`)}
                </h1>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Goals;
