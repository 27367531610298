import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

interface Iprops {
  onClick: (string: string) => void;
}
function Header(props: Iprops) {
  const { t } = useTranslation();
  const headers = ["Main", "Message", "Principles", "Goal", "Contact"];
  const [ind, setInd] = useState(0);
  const changeLanguage = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };

  return (
    <div className="w-full flex  justify-center bg-[#37547e]  h-[220px ">
      <div className=" flex-col w-full justify-center">
        <div
          className={`flex w-full px-4 pt-1 ${
            i18n.language === "ar" ? " justify-end" : "justify-start"
          }  `}
        >
          {i18n.language === "ar" ? (
            <h1
              className="cursor-pointer text-white "
              onClick={() => changeLanguage()}
            >
              English
            </h1>
          ) : (
            <h1
              className="cursor-pointer text-white"
              onClick={() => changeLanguage()}
            >
              العربية
            </h1>
          )}
        </div>

        <div className="flex h-auto w-full justify-center p-10">
          <img
            className="w-[225px] h-[200px]"
            src="/assets/images/PrimaryLogo.svg"
            alt=""
          />
        </div>

        <div className="flex w-full items-center justify-center flex-row pt-2">
          {headers.map((e, i) => (
            <div className="flex px-8 mobile:px-2 " key={i}>
              <h1
                onClick={() => {
                  setInd(i);
                  props.onClick(e);
                }}
                className={`flex-row font-Messri text-4xl cursor-pointer mobile:text-2xl  ${
                  ind == i ? "text-white" : "text-[#8596b3]"
                } `}
              >
                {t(`${e}`)}
              </h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Header;
