import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";
interface IProps {
  id: string;
}
const Principles = (props: IProps) => {
  const values = [
    {
      title: "Professionalism",
      description: "ProfessionalismDesc",
      image: "/assets/images/image 17.svg",
    },
    {
      title: "moral",
      description: "moralDesc",
      image: "/assets/images/image 15.svg",
    },
    {
      title: "committed",
      description: "committedDesc",
      image: "/assets/images/image 18.svg",
    },
  ];
  const { t } = useTranslation();

  return (
    <div className="flex w-full  justify-center pt-32  h-[400px] mobile:flex-col mobile:pt-[1050px] tablet:flex-col tablet:pt-[970px]">
      {values.map((e) => (
        <>
          <div
            id={props.id}
            className="flex flex-col justify-between items-center w-[600px] mobile:w-auto mobile:py-10 tablet:items-center tablet:justify-center  tablet:w-auto  "
          >
            <img className="w-[370px] h-[370px]" src={e.image} alt="" />
            <h1
              className={`tablet:flex w-[450px]  text-4xl text-center font-bold mobile:w-full px-2 pt-2 mobile:px-5 tablet:justify-center mobile:h-auto mobile:text-center font-Messri  ${
                i18n.language === "ar" ? "  " : " text-bold text-3xl  "
              } `}
            >
              {t(`${e.title}`)}
            </h1>
            <h1
              className={`tablet:flex w-[450px] text-2xl font-bold mobile:w-full px-2 mobile:px-5 tablet:justify-center mobile:flex mobile:h-auto mobile:text-center font-Messri  ${
                i18n.language === "ar"
                  ? "text-justify"
                  : " text-bold text-3xl text-end "
              } `}
            >
              <div className=" w-auto bg-black h-[2px] my-6 mobile:hidden tablet:hidden laptop:hidden text-justify"></div>
              {t(`${e.description}`)}
            </h1>
          </div>
        </>
      ))}
    </div>
  );
};

export default Principles;
