import Contact from "./components/user/contact";
import Discover from "./components/user/discover";
import Footer from "./components/user/footer";
import Goals from "./components/user/goals";
import Header from "./components/user/header";
import Message from "./components/user/message";
import Principles from "./components/user/principles";

function App() {
  const handleClick = (e: string) => {
    const element = document.getElementById(e);
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="w-auto flex flex-col h-auto justify-center overflow-hidden">
      <Header onClick={handleClick} />
      <Discover id="Discover" />
      <Message id="Message" />
      <Principles id="Principles" />
      <Goals id="Goal" />
      <Contact id="Contact" />
      <Footer />
    </div>
  );
}

export default App;
