import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center w-full pt-8 bg-[#37547e]">
      <div className="flex flex-col h-18 w-full justify-center items-center">
        <img
          className="w-[85px] h-[70px]"
          src="/assets/images/PrimaryLogo.svg"
          alt=""
        />
        <div className="bg-white w-[600px] h-[2px] my-8"></div>
        {/* <div className="flex flex-col justify-center ">
          <h1 className="text-white justify-center font-Messri flex py-6">
            {t("FollowUsAt")}
          </h1>
          <div className="flex flex-row gap-4 pb-9">
            <img src="/assets/images/in.svg" alt="" />
            <img src="/assets/images/insta.svg" alt="" />
            <img src="/assets/images/face.svg" alt="" />
            <img src="/assets/images/x.svg" alt="" />
          </div>
        </div> */}
        <h1 className="text-white pb-4 font-Messri text-center">
          {t("rights")}
        </h1>
      </div>
    </div>
  );
};

export default Footer;
