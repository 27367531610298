import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Clock from 'react-live-clock';
import i18n from 'src/core/configs/i18n';

import InputFiled2 from '../common/message-input';
import InputFiled from '../common/Text-Input';

interface IProps {
  id: string;
}

const Contact = (props: IProps) => {
  const { t } = useTranslation();
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const [message, setmessage] = useState('');

  const [submit, setsubmit] = useState(false);
  const handleSubmit = () => {
    if (
      firstName != '' &&
      lastName != '' &&
      email != '' &&
      phone != '' &&
      message != ''
    ) {
      setsubmit(true);
    }
  };

  const sendEmail = async () => {
    await axios.post('https://rq-api.onrender.com/send', {
      email: email,
      message: message,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
    });
    window.location.reload();
  };

  return (
    <div id={props.id} className="flex flex-col w-full justify-center pt-48  ">
      <div className="flex flex-row tablet:flex-col justify-around w-full px-12 mobile:px-0 mobile:flex-col laptop:flex-col laptop:justify-center largeLaptop:flex-col largeLaptop:justify-center tablet:justify-center tablet:items-center">
        <div className="flex flex-col gap-4 largeLaptop:justify-center largeLaptop:items-center laptop:justify-center laptop:items-center mobile:justify-center ">
          <h1
            className={`tablet:flex pt-5 px-2 tablet:justify-center text-3xl font-bold mobile:flex mobile:justify-center font-Messri  ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } `}
          >
            {t('contactUS')}
          </h1>

          <div className="flex  gap-8 laptop:flex-col tablet:flex-col mobile:flex-col ">
            <InputFiled
              hint={t('firstName').toString()}
              onChange={(e) => {
                setfirstName(e.target.value);
                handleSubmit();
              }}
            />
            <InputFiled
              hint={t('lastName').toString()}
              onChange={(e) => {
                setlastName(e.target.value);
                handleSubmit();
              }}
            />
          </div>
          <div className="flex gap-8 laptop:flex-col tablet:flex-col mobile:flex-col">
            <InputFiled
              hint={t('email').toString()}
              onChange={(e) => {
                setemail(e.target.value);
                handleSubmit();
              }}
            />
            <InputFiled
              hint={t('phone').toString()}
              onChange={(e) => {
                setphone(e.target.value);
                handleSubmit();
              }}
            />
          </div>
          <div className="w-auto text-2xl largeLaptop:justify-center largeLaptop:items-center laptop:justify-center laptop:items-center">
            <InputFiled2
              hint={t('yourMessage').toString()}
              onChange={(e) => {
                setmessage(e.target.value);
                handleSubmit();
              }}
              className="w-[800px] laptop:w-[370px]  h-[250px] border-[1.5px] px-4 border-[#022A3A] tablet:w-[370px] "
            />
          </div>
          <div className="flex tablet:justify-center py-3 mobile:justify-center">
            {' '}
            <button
              onClick={() => {
                submit ? sendEmail() : null;
              }}
              className={`${
                submit
                  ? 'bg-[#37547E] text-white text-2xl'
                  : 'bg-black cursor-auto text-gray-400 text-2xl'
              } mt-5 w-48 h-12  font-Messri`}
            >
              {t('send')}
            </button>
          </div>
        </div>
        <div className="flex justify-center">
          {' '}
          <a href="https://maps.app.goo.gl/3RGQsBa6PNtfkKkD6?g_st=ic">
            {' '}
            <img
              className="max-w-[800px] max-h-[600px] mobile:max-w-[300px] mobile:max-h-[300px] flex "
              src="/assets/images/map.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="flex flex-row justify-around w-full  p-4">
        <div className="flex justify-between gap-6 mobile:flex-col mobile:justify-center mobile:items-center">
          <div className="flex flex-row">
            {' '}
            <img
              className="max-w-10 max-h-10 px-2 mb-[1.5px]"
              src="/assets/images/Time-Square.svg"
              alt=""
            />
            <h1 className="font-Messri text-3xl">
              {' '}
              <Clock
                format={'hh:mm'}
                ticking={true}
                timezone={'Europe/Moscow'}
              />
            </h1>{' '}
          </div>
          <div className="flex flex-row">
            {' '}
            <img
              className="max-w-10 max-h-10 px-2"
              src="/assets/images/Call.svg"
              alt=""
            />
            <a
              className="text-2xl"
              href="//api.whatsapp.com/send?phone=+966558017999&text="
            >
              {'0558017999'}
            </a>{' '}
          </div>
          <div className="flex flex-row">
            {' '}
            <img
              className="max-w-10 max-h-10 px-2"
              src="/assets/images/Combined-Shape.svg"
              alt=""
            />
            <h1>
              <a className="text-2xl" href="mailto:a@rq.sa">
                a@rq.sa
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
