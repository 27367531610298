import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

interface IProps {
  id: string;
}

const Discover = (props: IProps) => {
  const { t } = useTranslation();
  const handleClick = () => {
    const element = document.getElementById("Contact");
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div
      id={props.id}
      className="flex h-auto flex-col w-full bg-[#37547e] mobile:flex-col mobile:items-center pt-32"
    >
      <div className="flex flex-row justify-around mobile:flex-col">
        <div className="flex-col  justify-center items-center ">
          <h1
            className={`font-Messri text-[90px] mobile:flex-col text-center text-3xl mobile:text-[50px]  text-white mobile:text-center`}
          >
            {t(`discover`)}
          </h1>
          <div className="flex flex-row gap-2 mobile:justify-center mobile: mobile:flex-row ">
            <h1 className="font-Messri text-[90px] mobile:text-[50px] text-white mobile:text-center">
              {t(`world`)}
            </h1>
            <h1 className="font-Messri text-[90px] text-[#9A9162] mobile:text-[50px] text-center">
              {t(`justice`)}
            </h1>
          </div>
        </div>
        <div
          className={`flex w-96 pt-4 mobile:px-4 ${
            i18n.language === "ar" ? null : "items-end justify-end"
          }`}
        >
          <h1
            className={`text-white font-Messri text-3xl font-bold mobile:px-4  ${
              i18n.language === "ar" ? " text-justify" : "text-bold text-end"
            }`}
          >
            {t(`offered`)}
          </h1>
        </div>
      </div>
      <div className="flex w-auto flex-row justify-around pt-8 mobile:flex-col mobile:justify-center">
        <div
          className={`flex w-auto flex-col items-center ${
            i18n.language === "ar"
              ? " desktop:px-24 largeDesktop:px-24 largeLaptop:px-24"
              : "desktop:px-36 largeDesktop:px-36 largeLaptop:px-36"
          }`}
        >
          <div className="bg-white w-[400px] flex h-[1px] my-5 mobile:hidden tablet:hidden"></div>
          <div className="flex justify-center pt-6">
            {" "}
            <button
              onClick={() => handleClick()}
              className="h-[50px] flex justify-center text-3xl items-center w-[250px] text-white font-Messri bg-[#9A9162] cursor-pointer"
            >
              {t("Contact")}
            </button>
          </div>
        </div>
        <div className="flex ">
          {" "}
          <img
            className="max-w-[670px] mobile:pt-8 mobile:max-w-[370px] tablet:max-w-[370px]"
            src="/assets/images/image 3.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Discover;
