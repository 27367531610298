import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

interface IProps {
  id: string;
}
const Message = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <div
      id={props.id}
      className="flex w-full flex-row justify-around tablet:flex-col pt-8 mobile:flex-col mobile:justify-center gap-8 tablet:flex tablet:justify-center"
    >
      <div className="flex justify-center items-center">
        <div className="flex justify-center  flex-col pt-6 gap-4 tablet:items-center mobile:text-center">
          <h1
            className={`tablet:flex w-[450px] text-center font-bold mobile:justify-center text-4xl mobile:w-full px-2 mobile:px-5 tablet:justify-center mobile:flex mobile:h-auto mobile:text-center font-Messri  ${
              i18n.language === "ar" ? "" : " text-bold"
            } `}
          >
            {t("Message")}
          </h1>
          <h1
            className={`tablet:flex w-[450px] text-2xl font-bold mobile:w-full px-2 mobile:px-5 tablet:justify-center mobile:flex mobile:h-auto mobile:text-center font-Messri  ${
              i18n.language === "ar"
                ? "text-start"
                : " text-bold text-3xl text-end "
            } `}
          >
            {t("ourMessage")}
          </h1>
        </div>
      </div>
      <div className="flex h-[500px] bg-black w-[2px] mobile:hidden tablet:hidden"></div>
      <div className="flex items-center justify-center">
        {" "}
        <h1
          className={`tablet:flex w-[450px] text-2xl font-bold mobile:w-full px-2 mobile:px-5 tablet:justify-center mobile:flex mobile:h-auto mobile:text-center font-Messri  ${
            i18n.language === "ar"
              ? "text-start"
              : "text-bold text-3xl text-end "
          } `}
        >
          {t("rq")}
        </h1>
      </div>
    </div>
  );
};

export default Message;
