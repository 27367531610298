import "./App.css";
import "./core/configs/i18n";

import React from "react";
import { createRoot } from "react-dom/client";

import { AppRouting } from "./app-routes";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<React.StrictMode>{AppRouting()}</React.StrictMode>);
